<template>
	<div class="w-full h-full flex flex-col justify-center items-center mt-32">
		<div class="space-y-4 flex flex-col items-center justify-center">
			<h2 class="text-gray-600 font-semibold text-md lg:text-2xl uppercase">{{ t("404.title", {}, { locale: $store.state.Langs.selected.lang }) }}</h2>
			<p class="w-4/5 lg:w-full text-sm lg:text-md text-gray-400">{{ t("404.invite", {}, { locale: $store.state.Langs.selected.lang }) }}</p>
			<div v-if="$router.query?.register == 1">
				<h2 class="text-gray-600 font-semibold text-md lg:text-2xl uppercase">{{ t("404.title", {}, { locale: $store.state.Langs.selected.lang }) }}</h2>
				<p class="w-4/5 lg:w-full text-sm lg:text-md text-gray-400">{{ t("404.register", {}, { locale: $store.state.Langs.selected.lang }) }}</p>
				<router-link :to="{ name: 'Register', params: { id: '' } }">
					<button class="btn btn-primary btn-xs">
						<span>{{ t("404.toRegister", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
					</button>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import { useI18n } from "vue-i18n"

export default {
	setup() {
		const { t, locale } = useI18n()
		return { t, locale }
	}
}
</script>
